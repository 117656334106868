<template>
  <div class="settings__templates">
    <div class="tiny__content">
      <p class="tiny__content__title">HOME OFFICE КАТЕГОРИИ</p>
      <hr class="line" />
      <p class="text">
        <strong> Може да добавяте нови категории, да редактирате и изтривате съществуващи. </strong>
      </p>
      <p class="text">
        <strong>
          Изтритите категории ще се показват във вече създадени отчети и в справките, но не и при създаване на нови отчети.
        </strong>
      </p>
      <div class="add" @click="add">
        <span class="icon">A</span>
        <p>НОВА КАТЕГОРИЯ</p>
      </div>
      <section class="row-wrapper">
        <div class="row" v-for="(f, i) in homeOfficeCategories" :key="f._id">
          <Input v-model="f.name" label="" :invalid="invalid === i" @input="invalid = ''" />
          <span class="delete icon" @click="deleteCategory(f._id, i)">{{ '{' }}</span>
        </div>
      </section>
      <div class="tiny__content__buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading">Запиши</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  data() {
    return {
      loading: false,
      invalid: '',
      success: undefined,
      homeOfficeCategories: [],
    };
  },
  methods: {
    cancel() {
      this.$router.push('/vacations');
    },
    add() {
      this.homeOfficeCategories.unshift({});
    },
    deleteCategory(id, index) {
      if (confirm('Сигурни ли сте, че искате да изтриете тази категория?')) {
        if (id) {
          let url = `/home-office-categories/${id}`;
          this.$apiService.delete(url).then(() => {
            this.gethOCategories();
          });
        }
        this.homeOfficeCategories.splice(index, 1);
        this.invalid = '';
      }
    },
    gethOCategories() {
      this.homeOfficeCategories = [];
      this.$apiService.get('/home-office-categories').then((res) => {
        this.homeOfficeCategories = res.data;
        if (!Object.keys(res.data).length) {
          this.add();
        }
      });
    },
    isValid() {
      for (let i = 0; i < this.homeOfficeCategories.length; i++) {
        let category = this.homeOfficeCategories[i];

        if (!category.name) {
          this.invalid = i;
          this.$snotify.error('Всички полета са задължителни!');
          return false;
        }
      }

      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;
        this.$apiService
          .post('/home-office-categories', this.homeOfficeCategories)
          .then(() => {
            this.success = true;
            this.$snotify.success('Имената са запазени успешно');
          })
          .catch((err) => {
            this.success = false;
            console.log(err);
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.success = undefined;
              this.gethOCategories();
            }, 1000);
          });
      }
    },
  },
  mounted() {
    this.gethOCategories();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.text {
  margin-bottom: $px11 / 2;
}
.add {
  align-items: center;
  background: #e2e9ee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 15px 0;
  width: 100%;
  @include noselect();
  .icon {
    color: #005a9e;
    font-size: 20px;
    margin-right: 5px;
  }
  p {
    font-weight: bold;
  }
}
.row-wrapper {
  max-height: 40vh;
  min-height: 40vh;
  overflow: auto;
  padding: 0 1px;
  @include transition($transition);
}
.row {
  position: relative;
  margin-top: 5px;
  width: 100%;
  &:hover {
    .delete {
      display: block;
    }
  }
  .input-wrapper {
    width: 100%;
  }
  .delete {
    bottom: 8px;
    cursor: pointer;
    color: #b71818;
    display: none;
    font-size: 20px;
    position: absolute;
    right: 10px;
    @include noselect();
  }
}
.tiny__content__buttons {
  align-items: center;
}
</style>
